var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.courseVideoVisible,
            width: "900px",
            "before-close": _vm.handleClose,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.courseVideoVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "videoForm",
              attrs: {
                model: { courseVideoList: _vm.courseVideoList },
                "inline-message": "",
                "label-width": "0",
              },
            },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.videoList, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", align: "center", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "视频名称",
                      align: "center",
                      prop: "title",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "courseVideoList." +
                                        scope.$index +
                                        ".title",
                                      rules: {
                                        required: true,
                                        message: "请输入视频名称",
                                        trigger: "blur",
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "请输入视频名称",
                                      },
                                      model: {
                                        value: scope.row.name,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "name", $$v)
                                        },
                                        expression: "scope.row.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "视频封面",
                      align: "center",
                      prop: "coverurl",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-wrap align-center justify-center",
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "courseVideoList." +
                                        scope.$index +
                                        ".coverurl",
                                      rules: {
                                        required: true,
                                        message: "请输入视频名称",
                                      },
                                    },
                                  },
                                  [
                                    _c("ImageUpload", {
                                      attrs: {
                                        limit: 1,
                                        isShowTip: false,
                                        value: scope.row.coverurl,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.handleUpload(
                                            $event,
                                            scope.$index
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center", prop: "lessonId" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        _vm.previewSrc = scope.row.videoSrc
                                        _vm.previewVideoVisible = true
                                      },
                                    },
                                  },
                                  [_vm._v(" 预览")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "#f56c6c" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleEnter } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频预览",
            visible: _vm.previewVideoVisible,
            width: "900px",
            "before-close": function () {
              _vm.previewVideoVisible = false
              _vm.previewSrc = ""
            },
          },
          on: {
            "update:visible": function ($event) {
              _vm.previewVideoVisible = $event
            },
          },
        },
        [
          _c("video", {
            attrs: {
              controls: "",
              width: "100%",
              autoplay: "",
              src: _vm.previewSrc,
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }