import { render, staticRenderFns } from "./courseVideoList.vue?vue&type=template&id=ea3a355e&scoped=true"
import script from "./courseVideoList.vue?vue&type=script&lang=js"
export * from "./courseVideoList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea3a355e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/jobs/mall-ui/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ea3a355e')) {
      api.createRecord('ea3a355e', component.options)
    } else {
      api.reload('ea3a355e', component.options)
    }
    module.hot.accept("./courseVideoList.vue?vue&type=template&id=ea3a355e&scoped=true", function () {
      api.rerender('ea3a355e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/virtualshoppingmall/onlineclass/components/courseVideoList.vue"
export default component.exports