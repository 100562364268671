import request from "@/utils/request";

// 查询线上课列表
export function listOnline(query) {
  return request({
    url: "/mall/lessonOnline/list",
    method: "get",
    params: query,
  });
}

// 查询线上课详细
export function getOnline(lessonId) {
  return request({
    url: "/mall/lessonOnline/" + lessonId,
    method: "get",
  });
}

// 新增线上课
export function addOnline(data) {
  return request({
    url: "/mall/lessonOnline",
    method: "post",
    data: data,
  });
}

// 新增线上课
export function addOnlineList(data) {
  return request({
    url: "/mall/lessonOnline/addList",
    method: "post",
    data: data,
  });
}

// 修改线上课
export function updateOnline(data) {
  return request({
    url: "/mall/lessonOnline",
    method: "put",
    data: data,
  });
}

// 删除线上课
export function delOnline(lessonIds) {
  return request({
    url: "/mall/lessonOnline/" + lessonIds,
    method: "delete",
  });
}

// 上架下架
export function shelfTrigger(data) {
  return request({
    url: "/mall/lessonOnline/release",
    method: "put",
    data: data,
  });
}

// 排序(上移下移)
export function sortOnline(data) {
  return request({
    url: "/mall/lessonOnline/sort",
    method: "put",
    data: data,
  });
}

// 编辑线上线下课
export function getLessonOnlineDetail(lessonId) {
  return request({
    url: `/mall/lessonOnline/${lessonId}`,
    method: "get",
  });
}

// 更新线上课
export function updateLessonOnline(data) {
  return request({
    url: `/mall/lessonOnline`,
    method: "put",
    data,
  });
}

// 获取线上课列表
export function getOnlineList(query) {
  return request({
    url: "/mall/lessonOnline/jjtLessons",
    method: "get",
    params: query,
  });
}

// 晋级通线上课 详情
export function getOnlineDetail(query) {
  return request({
    url: "/mall/lessonOnline/jjtLessonsInfo",
    method: "get",
    params: query,
  });
}
