var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register" },
    [
      _c(
        "el-form",
        {
          ref: "registerForm",
          staticClass: "register-form",
          attrs: { model: _vm.registerForm, rules: _vm.registerRules },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("若依后台管理系统")]),
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "text",
                    "auto-complete": "off",
                    placeholder: "账号",
                  },
                  model: {
                    value: _vm.registerForm.username,
                    callback: function ($$v) {
                      _vm.$set(_vm.registerForm, "username", $$v)
                    },
                    expression: "registerForm.username",
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon input-icon",
                    attrs: { slot: "prefix", "icon-class": "user" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "password",
                    "auto-complete": "off",
                    placeholder: "密码",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleRegister($event)
                    },
                  },
                  model: {
                    value: _vm.registerForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.registerForm, "password", $$v)
                    },
                    expression: "registerForm.password",
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon input-icon",
                    attrs: { slot: "prefix", "icon-class": "password" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "confirmPassword" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "password",
                    "auto-complete": "off",
                    placeholder: "确认密码",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleRegister($event)
                    },
                  },
                  model: {
                    value: _vm.registerForm.confirmPassword,
                    callback: function ($$v) {
                      _vm.$set(_vm.registerForm, "confirmPassword", $$v)
                    },
                    expression: "registerForm.confirmPassword",
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon input-icon",
                    attrs: { slot: "prefix", "icon-class": "password" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.captchaEnabled
            ? _c(
                "el-form-item",
                { attrs: { prop: "code" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "63%" },
                      attrs: { "auto-complete": "off", placeholder: "验证码" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleRegister($event)
                        },
                      },
                      model: {
                        value: _vm.registerForm.code,
                        callback: function ($$v) {
                          _vm.$set(_vm.registerForm, "code", $$v)
                        },
                        expression: "registerForm.code",
                      },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "el-input__icon input-icon",
                        attrs: { slot: "prefix", "icon-class": "validCode" },
                        slot: "prefix",
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "register-code" }, [
                    _c("img", {
                      staticClass: "register-code-img",
                      attrs: { src: _vm.codeUrl },
                      on: { click: _vm.getCode },
                    }),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    loading: _vm.loading,
                    size: "medium",
                    type: "primary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleRegister($event)
                    },
                  },
                },
                [
                  !_vm.loading
                    ? _c("span", [_vm._v("注 册")])
                    : _c("span", [_vm._v("注 册 中...")]),
                ]
              ),
              _c(
                "div",
                { staticStyle: { float: "right" } },
                [
                  _c(
                    "router-link",
                    { staticClass: "link-type", attrs: { to: "/login" } },
                    [_vm._v("使用已有账户登录")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "el-register-footer" }, [
      _c("span", [
        _vm._v("Copyright © 2018-2024 ruoyi.vip All Rights Reserved."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }