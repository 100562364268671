import request from "@/utils/request";

// 线上课报名列表
export function onlineNameList(query) {
  return request({
    url: "/mall/virtualOrder/onlineSignList",
    method: "get",
    params: query,
  });
}

// 线上课报名导出
export function onlineExport() {
  return request({
    url: "/mall/virtualOrder/onlineSignExport",
    method: "post",
  });
}

// 线下课报名列表
export function lineNameList(query) {
  return request({
    url: "/mall/virtualOrder/offlineSignList",
    method: "get",
    params: query,
  });
}

// 线下课报名列表人数统计

export function lineNameListCount(query) {
  return request({
    url: "/mall/virtualOrder/offlineSignListCount",
    method: "get",
    params: query,
  });
}

// 研学课报名列表
export function researchList(query) {
  return request({
    url: "/mall/virtualOrder/yanxueSignList",
    method: "get",
    params: query,
  });
}

// 研学课报名列表
export function researchListCount(query) {
  return request({
    url: "/mall/virtualOrder/yanxueSignListCount",
    method: "get",
    params: query,
  });
}

// 修改报名信息
export function offlineSignEdit(data) {
  return request({
    url: "/mall/virtualOrder/offlineSignEdit",
    method: "post",
    data,
  });
}
