var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container add-course-container" },
    [
      _c(
        "el-row",
        { staticClass: "mb40" },
        [
          _vm._v(" 添加课程 "),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "primary", size: "mini", plain: "" },
              on: {
                click: function ($event) {
                  _vm.checkCourseVisible = true
                },
              },
            },
            [_vm._v("去添加")]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-form",
            {
              ref: "courseForm",
              attrs: {
                model: { checkCourseList: _vm.checkCourseList },
                "inline-message": "",
                "label-width": "0",
              },
            },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.checkCourseList, border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "课程编号",
                      align: "center",
                      prop: "lessonNo",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "课程名称", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "checkCourseList." +
                                    scope.$index +
                                    ".lessonName",
                                  rules: {
                                    required: true,
                                    message: "请输入课程名称",
                                    trigger: "blur",
                                  },
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: scope.row.lessonName,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "lessonName", $$v)
                                    },
                                    expression: "scope.row.lessonName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "单价", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "checkCourseList." +
                                    scope.$index +
                                    ".price",
                                  rules: {
                                    required: true,
                                    validator: _vm.checkPrice,
                                    trigger: "blur",
                                  },
                                },
                              },
                              [
                                _c("el-input", {
                                  on: {
                                    input: function ($event) {
                                      return _vm.priceInput(
                                        $event,
                                        scope.$index
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.price,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "price", $$v)
                                    },
                                    expression: "scope.row.price",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "封面图", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "checkCourseList." +
                                    scope.$index +
                                    ".coverImage",
                                  rules: {
                                    required: true,
                                    message: "请上传封面图",
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex flex-wrap align-center justify-center",
                                  },
                                  [
                                    _c("ImageUpload", {
                                      attrs: {
                                        limit: 1,
                                        isShowTip: false,
                                        value: scope.row.coverImage,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.handleUpload(
                                            $event,
                                            "coverImage",
                                            scope.$index
                                          )
                                        },
                                      },
                                    }),
                                    _c("div", { staticClass: "tip" }, [
                                      _vm._v("尺寸：180*180"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "轮播图", align: "center", width: "300" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "checkCourseList." +
                                    scope.$index +
                                    ".bannerImages",
                                  rules: {
                                    required: true,
                                    message: "请上传轮播图",
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex flex-wrap align-center justify-center",
                                  },
                                  [
                                    _c("ImageUpload", {
                                      attrs: {
                                        limit: 5,
                                        isShowTip: false,
                                        value: scope.row.bannerImages,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.handleUpload(
                                            $event,
                                            "bannerImages",
                                            scope.$index
                                          )
                                        },
                                      },
                                    }),
                                    _c("div", { staticClass: "tip" }, [
                                      _c("p", [_vm._v("尺寸：180*180")]),
                                      _c("p", [
                                        _vm._v("最多可上传"),
                                        _c("strong", [_vm._v("5")]),
                                        _vm._v("张"),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "详情图", align: "center", width: "300" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "checkCourseList." +
                                    scope.$index +
                                    ".detailImages",
                                  rules: {
                                    required: true,
                                    message: "请上传详情图",
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex flex-wrap align-center justify-center",
                                  },
                                  [
                                    _c("ImageUpload", {
                                      attrs: {
                                        limit: 5,
                                        isShowTip: false,
                                        value: scope.row.detailImages,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.handleUpload(
                                            $event,
                                            "detailImages",
                                            scope.$index
                                          )
                                        },
                                      },
                                    }),
                                    _c("div", { staticClass: "tip" }, [
                                      _c("p", [_vm._v("尺寸：180*180")]),
                                      _c("p", [
                                        _vm._v("最多可上传"),
                                        _c("strong", [_vm._v("5")]),
                                        _vm._v("张"),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "视频", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.videoList.length || 0) +
                                " "
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "ml10",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    _vm.checkCourseIndex = scope.$index
                                    _vm.courseVideoVisible = true
                                  },
                                },
                              },
                              [
                                _vm._v("查看编辑"),
                                _c("i", { staticClass: "el-icon-arrow-right" }),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "是否上架", align: "center", width: "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "inline-prompt": "",
                                "active-value": "1",
                                "inactive-value": "0",
                                "inactive-text": "下架",
                                "active-text": "上架",
                              },
                              model: {
                                value: scope.row.release,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "release", $$v)
                                },
                                expression: "scope.row.release",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-row", [
        _c(
          "div",
          { staticClass: "handle-btn" },
          [
            _c(
              "el-button",
              { staticClass: "btn mr20", on: { click: _vm.handleCancel } },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                staticClass: "btn",
                attrs: { type: "primary" },
                on: { click: _vm.handleSubmit },
              },
              [_vm._v("确定")]
            ),
          ],
          1
        ),
      ]),
      _c("checkCourse", {
        attrs: {
          checkCourseVisible: _vm.checkCourseVisible,
          checkCourseIds: _vm.checkCourseIds,
        },
        on: {
          "update:checkCourseVisible": function ($event) {
            _vm.checkCourseVisible = $event
          },
          "update:check-course-visible": function ($event) {
            _vm.checkCourseVisible = $event
          },
          _enter: _vm.handleEnterCourse,
        },
      }),
      _c("courseVideoList", {
        attrs: {
          courseVideoVisible: _vm.courseVideoVisible,
          videoList: _vm.checkCourseList[_vm.checkCourseIndex].videoList,
          dialogTitle: _vm.checkCourseList[_vm.checkCourseIndex].lessonName,
        },
        on: {
          "update:courseVideoVisible": function ($event) {
            _vm.courseVideoVisible = $event
          },
          "update:course-video-visible": function ($event) {
            _vm.courseVideoVisible = $event
          },
          _saveVideo: _vm.handleSaveVideo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }