var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "",
        visible: _vm.checkCourseVisible,
        width: "900px",
        "before-close": _vm.handleClose,
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.checkCourseVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "queryForm",
          staticClass: "mb20",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "98px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "课程名称", prop: "keyword" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入课程名称", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.keyword,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "keyword", $$v)
                  },
                  expression: "queryParams.keyword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程编号", prop: "id" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入课程编号", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "id", $$v)
                  },
                  expression: "queryParams.id",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "multipleTable",
          attrs: {
            border: "",
            data: _vm.courseList,
            "row-key": _vm.getRowKey,
            height: "60vh",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "selection",
              width: "55",
              "reserve-selection": true,
            },
          }),
          _c("el-table-column", {
            attrs: { label: "课程编号", align: "center", prop: "id" },
          }),
          _c("el-table-column", {
            attrs: { label: "课程名称", align: "center", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "单价", align: "center", prop: "price" },
          }),
          _c("el-table-column", {
            attrs: { label: "封面图", align: "center", prop: "avatar" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        scope.row.avatar
                          ? _c("el-image", {
                              staticStyle: { width: "50px", height: "50px" },
                              attrs: {
                                src: scope.row.avatar,
                                "preview-src-list": [scope.row.avatar],
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "轮播图", align: "center", prop: "slide" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.slide && scope.row.slide.length > 0
                      ? _c(
                          "div",
                          _vm._l(scope.row.slide, function (scopes) {
                            return _c("el-image", {
                              key: scopes,
                              staticStyle: { width: "50px", height: "50px" },
                              attrs: {
                                src: scopes,
                                "preview-src-list": scope.row.slide,
                              },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "详情介绍", align: "center", prop: "price" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.content
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.previewDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("预览")]
                        )
                      : _c("span", [_vm._v("/")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "视频数量", align: "center", prop: "vid_count" },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleEnter } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }