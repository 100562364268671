<template>
  <el-dialog
    title=""
    :visible.sync="checkCourseVisible"
    width="900px"
    :before-close="handleClose"
    center
  >
    <el-form
      class="mb20"
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      label-width="98px"
    >
      <el-form-item label="课程名称" prop="keyword">
        <el-input
          v-model="queryParams.keyword"
          placeholder="请输入课程名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="课程编号" prop="id">
        <el-input
          v-model="queryParams.id"
          placeholder="请输入课程编号"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      border
      v-loading="loading"
      :data="courseList"
      @selection-change="handleSelectionChange"
      ref="multipleTable"
      :row-key="getRowKey"
      height="60vh"
    >
      <el-table-column type="selection" width="55" :reserve-selection="true">
      </el-table-column>
      <el-table-column label="课程编号" align="center" prop="id" />
      <el-table-column label="课程名称" align="center" prop="name" />
      <el-table-column label="单价" align="center" prop="price" />
      <el-table-column label="封面图" align="center" prop="avatar">
        <template slot-scope="scope">
          <div>
            <el-image
              v-if="scope.row.avatar"
              style="width: 50px; height: 50px"
              :src="scope.row.avatar"
              :preview-src-list="[scope.row.avatar]"
            >
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="轮播图" align="center" prop="slide">
        <template slot-scope="scope">
          <div v-if="scope.row.slide && scope.row.slide.length > 0">
            <el-image
              v-for="scopes in scope.row.slide"
              :key="scopes"
              style="width: 50px; height: 50px"
              :src="scopes"
              :preview-src-list="scope.row.slide"
            >
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="详情介绍" align="center" prop="price">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.content"
            type="text"
            @click="previewDetail(scope.row)"
            >预览</el-button
          >
          <span v-else>/</span>
        </template>
      </el-table-column>
      <el-table-column label="视频数量" align="center" prop="vid_count" />
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleEnter">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getOnlineList } from "@/api/virtualshoppingmall/onlineclass";
export default {
  props: {
    checkCourseVisible: {
      type: Boolean,
      default: false,
    },
    checkCourseIds: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    checkCourseIds: {
      handler(val) {
        console.log(val.length);
        val.forEach((element) => {
          this.$refs.multipleTable.toggleRowSelection(element, true);
        });
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      previewContent: false,
      total: 0,
      loading: false,
      courseList: [], //课程列表
      courseIds: [], //课程id
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        keyword: "",
        id: "",
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handlePreviewContentClose() {
      this.previewContent = false;
    },
    previewDetail(e) {
      console.log(e);
      this.$emit("previewDetail", e);
    },
    getRowKey(row) {
      return row.id;
    },
    getList() {
      this.loading = true;
      let data = {
        page: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        keyword: this.queryParams.keyword,
        id: this.queryParams.id,
      };
      getOnlineList(data).then((res) => {
        console.log(res);
        res.data.list = res.data.list.map((v) => {
          return {
            ...v,
            slide: (v.slide && JSON.parse(v.slide)) || (v.avatar && [v.avatar]),
          };
        });
        console.log("res.data.list", res.data.list);

        this.courseList = res.data.list;
        this.loading = false;
        this.total = +res.data.row_count;
      });
    },
    handleSelectionChange(val) {
      console.log("val", val);

      this.courseIds = val;
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    // 确认选择的课程
    handleEnter() {
      this.$emit("_enter", {
        courseIds: this.courseIds,
        courseList: this.courseList.some((item) =>
          this.courseIds.includes(item.id)
        ),
      });
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    handleClose() {
      this.$emit("update:checkCourseVisible", false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
