<template>
  <div class="app-container add-course-container">
    <el-row class="mb40">
      添加课程
      <el-button
        class="ml10"
        type="primary"
        size="mini"
        plain
        @click="checkCourseVisible = true"
        >去添加</el-button
      >
    </el-row>

    <el-row>
      <el-form
        :model="{ checkCourseList }"
        inline-message
        ref="courseForm"
        label-width="0"
      >
        <el-table :data="checkCourseList" border>
          <el-table-column label="课程编号" align="center" prop="lessonNo">
          </el-table-column>
          <el-table-column label="课程名称" align="center">
            <template slot-scope="scope">
              <el-form-item
                :prop="`checkCourseList.${scope.$index}.lessonName`"
                :rules="{
                  required: true,
                  message: '请输入课程名称',
                  trigger: 'blur',
                }"
              >
                <el-input v-model="scope.row.lessonName"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="单价" align="center">
            <template slot-scope="scope">
              <el-form-item
                :prop="`checkCourseList.${scope.$index}.price`"
                :rules="{
                  required: true,
                  validator: checkPrice,
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-model="scope.row.price"
                  @input="priceInput($event, scope.$index)"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="封面图" align="center">
            <template slot-scope="scope">
              <el-form-item
                :prop="`checkCourseList.${scope.$index}.coverImage`"
                :rules="{
                  required: true,
                  message: '请上传封面图',
                }"
              >
                <div class="flex flex-wrap align-center justify-center">
                  <ImageUpload
                    :limit="1"
                    :isShowTip="false"
                    @input="handleUpload($event, 'coverImage', scope.$index)"
                    :value="scope.row.coverImage"
                  />
                  <div class="tip">尺寸：180*180</div>
                </div>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="轮播图" align="center" width="300">
            <template slot-scope="scope">
              <el-form-item
                :prop="`checkCourseList.${scope.$index}.bannerImages`"
                :rules="{
                  required: true,
                  message: '请上传轮播图',
                }"
              >
                <div class="flex flex-wrap align-center justify-center">
                  <ImageUpload
                    :limit="5"
                    :isShowTip="false"
                    @input="handleUpload($event, 'bannerImages', scope.$index)"
                    :value="scope.row.bannerImages"
                  />
                  <div class="tip">
                    <p>尺寸：180*180</p>
                    <p>最多可上传<strong>5</strong>张</p>
                  </div>
                </div>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="详情图" align="center" width="300">
            <template slot-scope="scope">
              <el-form-item
                :prop="`checkCourseList.${scope.$index}.detailImages`"
                :rules="{
                  required: true,
                  message: '请上传详情图',
                }"
              >
                <div class="flex flex-wrap align-center justify-center">
                  <ImageUpload
                    :limit="5"
                    :isShowTip="false"
                    @input="handleUpload($event, 'detailImages', scope.$index)"
                    :value="scope.row.detailImages"
                  />
                  <div class="tip">
                    <p>尺寸：180*180</p>
                    <p>最多可上传<strong>5</strong>张</p>
                  </div>
                </div>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="视频" align="center">
            <template slot-scope="scope">
              {{ scope.row.videoList.length || 0 }}
              <el-button
                type="text"
                class="ml10"
                @click="
                  checkCourseIndex = scope.$index;
                  courseVideoVisible = true;
                "
                >查看编辑<i class="el-icon-arrow-right"></i
              ></el-button>
            </template>
          </el-table-column>
          <el-table-column label="是否上架" align="center" width="200">
            <template slot-scope="scope">
              <el-switch
                inline-prompt
                v-model="scope.row.release"
                active-value="1"
                inactive-value="0"
                inactive-text="下架"
                active-text="上架"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-row>
    <el-row>
      <div class="handle-btn">
        <el-button class="btn mr20" @click="handleCancel">取消</el-button>
        <el-button type="primary" class="btn" @click="handleSubmit"
          >确定</el-button
        >
      </div>
    </el-row>
    <checkCourse
      :checkCourseVisible.sync="checkCourseVisible"
      :checkCourseIds="checkCourseIds"
      @_enter="handleEnterCourse"
    />
    <courseVideoList
      :courseVideoVisible.sync="courseVideoVisible"
      :videoList="checkCourseList[checkCourseIndex].videoList"
      :dialogTitle="checkCourseList[checkCourseIndex].lessonName"
      @_saveVideo="handleSaveVideo"
    />
  </div>
</template>

<script>
import checkCourse from "./components/checkCourse.vue";
import courseVideoList from "./components/courseVideoList";
export default {
  components: { checkCourse, courseVideoList },
  data() {
    return {
      checkCourseVisible: false, //选择课程弹窗
      courseVideoVisible: false, //课程视频弹窗
      checkCourseIds: [], //选择的课程id集合
      checkCourseIndex: 0, //选择视频时的课程索引
      checkCourseList: [
        {
          lessonNo: "202405210001",
          lessonName: "校区招生7大秘诀",
          price: 3000,
          release: "1",
          coverImage: [],
          bannerImages: [],
          detailImages: [],
          videoList: [
            {
              name: "校区招生7大秘诀第1节",
              coverImage: "",
              videoSrc: "",
            },
          ],
        },
      ],
    };
  },
  methods: {
    // 保存 新增/编辑操作
    handleSubmit() {
      this.$refs["courseForm"].validate((valid) => {
        if (valid) {
          console.log("this.checkCourseList :>> ", this.checkCourseList);
          // this.$message.success("保存成功");
          // this.$router.go(-1);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 取消 编辑/新增
    handleCancel() {
      this.$router.go(-1);
    },
    // 保存视频编辑操作
    handleSaveVideo(data) {
      this.$set(this.checkCourseList[this.checkCourseIndex], "videoList", data);
    },
    // 上传成功回调
    handleUpload(data, str, index) {
      this.$set(this.checkCourseList[index], `${str}`, data);
      this.$refs.courseForm.validateField(`checkCourseList.${index}.${str}`);
    },
    // 选中课程
    handleEnterCourse({ courseIds, courseList }) {
      this.checkCourseList = courseList;
      this.checkCourseIds = courseIds;
    },
    // 校验单价
    checkPrice(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入单价"));
      } else {
        if (value < 0.01) {
          callback(new Error("单价不能为0"));
        }
        callback();
      }
    },
    // 输入金额正则
    priceInput(value, index) {
      let val = value
        .replace(/[^\d.]/g, "")
        .replace(/\.{2,}/g, ".")
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".")
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3")
        .replace(/^\./g, "")
        .replace(/^0\d/, "0");
      this.$set(this.checkCourseList[index], "price", val);
    },
  },
};
</script>
<style lang="scss">
.add-course-container {
  .tip {
    font-size: 12px;
    line-height: 1.5;
    color: #7f7f7f;
    p {
      margin: 0;
    }
  }
  .handle-btn {
    text-align: right;
    margin-top: 200px;
    .btn {
      width: 132px;
    }
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .el-switch__label--left {
    position: relative;
    left: 70px;
    color: #fff;
    z-index: -1111;
    span {
      width: 40px;
    }
  }
  .el-switch__label--right {
    position: relative;
    right: 70px;
    color: #fff;
    z-index: -1111;
    span {
      display: inline-block;
      width: 40px;
    }
  }
  .el-switch__core {
    width: 65px !important;
  }
  .el-switch__label--right.is-active {
    z-index: 10;
    color: #fff !important;
  }
  .el-switch__label--left.is-active {
    z-index: 10;
    color: #9c9c9c !important;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
  .el-upload--picture-card {
    width: 60px;
    height: 60px;
    line-height: 60px;
    display: inline-block;
    margin-right: 10px;
    .el-icon-plus {
      line-height: 60px;
    }
  }
  .hide .el-upload--picture-card {
    display: none;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 60px;
    height: 60px;
    margin-bottom: 0;
  }
}
</style>
