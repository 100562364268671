var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.user, rules: _vm.rules, "label-width": "80px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "旧密码", prop: "oldPassword" } },
        [
          _c("el-input", {
            attrs: {
              placeholder: "请输入旧密码",
              type: "password",
              "show-password": "",
            },
            model: {
              value: _vm.user.oldPassword,
              callback: function ($$v) {
                _vm.$set(_vm.user, "oldPassword", $$v)
              },
              expression: "user.oldPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "新密码", prop: "newPassword" } },
        [
          _c("el-input", {
            attrs: {
              placeholder: "请输入新密码",
              type: "password",
              "show-password": "",
            },
            model: {
              value: _vm.user.newPassword,
              callback: function ($$v) {
                _vm.$set(_vm.user, "newPassword", $$v)
              },
              expression: "user.newPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "确认密码", prop: "confirmPassword" } },
        [
          _c("el-input", {
            attrs: {
              placeholder: "请确认新密码",
              type: "password",
              "show-password": "",
            },
            model: {
              value: _vm.user.confirmPassword,
              callback: function ($$v) {
                _vm.$set(_vm.user, "confirmPassword", $$v)
              },
              expression: "user.confirmPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.submit },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "mini" },
              on: { click: _vm.close },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }