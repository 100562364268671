<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="courseVideoVisible"
      width="900px"
      :before-close="handleClose"
      center
    >
      <el-form
        :model="{ courseVideoList }"
        inline-message
        ref="videoForm"
        label-width="0"
      >
        <el-table :data="videoList" border>
          <el-table-column label="序号" align="center" width="100">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="视频名称" align="center" prop="title">
            <template slot-scope="scope">
              <div>
                <el-form-item
                  :prop="`courseVideoList.${scope.$index}.title`"
                  :rules="{
                    required: true,
                    message: '请输入视频名称',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="scope.row.name"
                    disabled
                    placeholder="请输入视频名称"
                  ></el-input>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="视频封面" align="center" prop="coverurl">
            <template slot-scope="scope">
              <div class="flex flex-wrap align-center justify-center">
                <el-form-item
                  :prop="`courseVideoList.${scope.$index}.coverurl`"
                  :rules="{
                    required: true,
                    message: '请输入视频名称',
                  }"
                >
                  <ImageUpload
                    :limit="1"
                    :isShowTip="false"
                    @input="handleUpload($event, scope.$index)"
                    :value="scope.row.coverurl"
                /></el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" prop="lessonId">
            <template slot-scope="scope">
              <div>
                <el-button
                  type="text"
                  @click="
                    previewSrc = scope.row.videoSrc;
                    previewVideoVisible = true;
                  "
                >
                  预览</el-button
                >
                <el-button
                  type="text"
                  style="color: #f56c6c"
                  @click="handleDelete(scope.$index)"
                >
                  删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleEnter">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="视频预览"
      :visible.sync="previewVideoVisible"
      width="900px"
      :before-close="
        () => {
          previewVideoVisible = false;
          previewSrc = '';
        }
      "
    >
      <video controls width="100%" autoplay :src="previewSrc"></video>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from "@/utils/index.js";
export default {
  props: {
    courseVideoVisible: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    videoList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      courseVideoList: [],
      previewVideoVisible: false,
      previewSrc: "",
    };
  },
  watch: {
    courseVideoVisible(val) {
      if (val) {
        this.courseVideoList = deepClone(this.videoList);
      }
    },
  },
  methods: {
    handleEnter() {
      this.$refs["videoForm"].validate((valid) => {
        if (valid) {
          this.$emit("update:courseVideoVisible", false);
          this.$emit("_saveVideo", this.courseVideoList);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleDelete(index) {
      if (this.courseVideoList.length < 2)
        return this.$message.error("至少保留一个视频课程");
      this.$confirm("确定删除该视频？", "系统确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.courseVideoList.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {});
    },
    handleUpload(data, index) {
      this.$set(this.courseVideoList[index], "coverImage", data);
      this.$refs.videoForm.validateField(`courseVideoList.${index}.coverImage`);
    },
    handleClose() {
      this.$emit("update:courseVideoVisible", false);
      this.resetForm("videoForm");
    },
  },
};
</script>

<style lang="scss" scoped></style>
